// CORE LIBRARIES
import React from "react";
import {graphql} from "gatsby";
import {RichText} from "prismic-reactjs";

// COMPONENTS
import Head from "../components/head/head";
import Header from "../components/header/header";
import Navigation from "../components/navigation";
import Footer from "../components/footer/footer";
import CourseList from "../components/lists/courseList";

export const query = graphql`
  {
    prismic {
      allPages(uid: null) {
        edges {
          node {
            _meta {
              uid
            }
            page_title
          }
        }
      }
      allCourses(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            course_title
            course_author
            course_desc
            course_thumb
          }
        }
      }
    }
  }
`;

export default ({data}) => {
  const courses = data.prismic.allCourses.edges;
  const page = data.prismic.allPages.edges.slice(0, 1).pop();

  if (!page) return null;

  return (
    <div>
      <Head title="TriMotion Tutorials" />
      <Navigation />
      <Header page_title={RichText.asText(page.node.page_title)} />
      <div className="pt-l pb-l">
        <CourseList courses={courses} />
      </div>
      <Footer />
    </div>
  );
};
