import React from "react";
import {Link} from "gatsby";
import {RichText, Date} from "prismic-reactjs";
import {linkResolver} from "../../utils/linkResolver";

import Card from "../cards/card";


export default ({courses}) => {
  if (!courses) return null;

  return (
    <div>
      <div className="container">
          <div className="flexcontainer">
        {courses.map((course) => {
          return (
            <Link key={course.node._meta.id} to={linkResolver(course.node._meta)}>
              <Card
                img_link={course.node.course_thumb.url}
                title={RichText.asText(course.node.course_title.slice(0, 1))}
                author={course.node.course_author}
              />
            </Link>
          );
        })}
        </div>
      </div>
    </div>
  );
};