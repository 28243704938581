import React, {Fragment, useState, useRef} from "react";
import {useSpring, animated} from "react-spring";

const Card = ({title = "", desc = "", author = "", img_link = "", img_alt = ""}) => {
  const ref = useRef();

  const [isHovered, setHovered] = useState(false);
  const [animatedProps, setAnimatedProps] = useSpring(() => {
    return {
      xys: [0, 0, 1],
    };
  });

  return (
    <Fragment>
      <animated.div
        ref={ref}
        className="card flex column flex-center"
        onMouseEnter={() => setHovered(true)}
        onMouseMove={() => {
          // Update values to animate to
          setAnimatedProps({
            xys: [0, 0, 1.05],
          });
        }}
        onMouseLeave={() => {
          setHovered(false);
          // Set xys back to original
          setAnimatedProps({xys: [0, 0, 1]});
        }}
        style={{
          // If hovered we want it to overlap other cards when it scales up
          zIndex: isHovered ? 2 : 1,
          // Interpolate function to handle css changes
          transform: animatedProps.xys.interpolate(
            (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
          ),
        }}
      >
        <div className="card-img">
          <img src={img_link} alt={img_alt} style={{height: "80%"}} />
        </div>
        <div className="card-bar">
          <div className="pl-m pt-m">
            <h1 className="title pb-s">{title}</h1>
            <span>
              Door: <span className="card-bar__author">{author}</span>
            </span>
          </div>
        </div>
      </animated.div>
    </Fragment>
  );
};

export default Card;
